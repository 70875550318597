<template>
  <v-dialog
    fullscreen
    :value="visible"
    @click:outside="$emit('close')"
    height="100%"
  >
    <v-card v-if="ytLink" style="d-flex align-center">
      <!-- <div class="iframe-close-button">
          <v-btn icon><v-icon>mdi-close</v-icon></v-btn>
        </div> -->
      <iframe
        height="100%"
        width="100%"
        class="youtube-frame"
        :src="ytLink"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <v-fab-transition>
        <v-btn
          class="close-button"
          color="white"
          style="opacity: 85%"
          small
          dark
          top
          right
          fixed
          fab
          @click="$emit('close')"
        >
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "FullScreenYTDialog",
  props: {
    link: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      ytLink: null,
    };
  },
  created() {
    this.link = this.link.replace(/shorts\//g, '');
    this.link = this.link.replace(/youtube.com/g, 'youtu.be');
    var VID_REGEX =
      /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    var videoId = this.link.match(VID_REGEX)[1];
    this.ytLink = `https://youtube.com/embed/${videoId}/`;
  },
};
</script>
<style scoped>
.youtube-frame {
  width: 100%;
  height: 100%;
  position: absolute;
}
</style>